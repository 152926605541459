<script>
import { ref, reactive, computed, onMounted, watch } from 'vue';
// import { useI18n } from 'vue-i18n'
import axios from 'axios';
import ClipboardJS from 'clipboard';
import Pagination from '@/components/pagination.vue'

export default {
  props: {
    window: Object
  },
  components: {
    Pagination
  },
  setup() {
    const input_text = ref('')
    const maxLength = 8000;
    let hasAlerted = false;
    const output_text = ref('')
    const showToast = ref(false); // 토스트 팝업 표시 여부
    const output_vocab = ref({})
    const loading = ref(false)

    const show_frequent_terms = ref(true)
    const frequent_terms = [
      { eng: 'Capital Reduction', kor: '감자' },
      { eng: 'No. of Shares', kor: '주식수' },
      { eng: 'Shareholder', kor: '주주' },
      { eng: 'Assignment', kor: '양도' },
      { eng: 'Auditor', kor: '감사' },
      { eng: 'Disclosure', kor: '공시' },
      { eng: 'Date', kor: '일자' },
      { eng: 'Financial', kor: '재무' },
      { eng: 'Disposal', kor: '처분' },
      { eng: 'Entrustment', kor: '위탁' }
    ];
    const keyword = ref('')
    const output_keyword = ref([]);  // 원본 데이터를 저장할 배열
    const currentPageData = ref([]); // 현재 페이지에 보여줄 데이터를 저장할 배열

    const page = ref(1); // 페이지 번호
    const limit = 20; // 페이지 번호
    const keyword_pagination = ref(0);

    function pagination() {
      const output_keyword_length = output_keyword.value.length;
      keyword_pagination.value = Math.ceil(output_keyword_length / limit); // 전체 페이지 수 계산
    }

    function updateCurrentPageData() {
      const start = (page.value - 1) * limit;
      const end = start + limit;
      currentPageData.value = output_keyword.value.slice(start, end);  // 현재 페이지에 해당하는 데이터만 슬라이스하여 저장
    }

    watch([page, output_keyword], updateCurrentPageData);

    const handleClickPage = (pageIndex) => {
      page.value = pageIndex;  // 페이지 값 업데이트
    };

    function checkLength() {
      if (input_text.value.length >= maxLength) {
        if (!hasAlerted) {
          alert('8000자를 넘을 수 없습니다.');
          hasAlerted = true; // 경고가 한 번 띄워졌음을 기록
        }
        input_text.value = input_text.value.slice(0, maxLength); // 추가 입력 방지
      } else {
        hasAlerted = false;
      }
    }

    const translate = async () => {
      output_text.value = [];
      loading.value = true;
      try {
        // Axios 요청을 보냅니다.
        const response = await axios.post('/staition_dev/translate', {
          "user_input": input_text.value
        },);

        // 요청이 성공했고 결과가 true인 경우
        if (response.data.result === true) {
          output_text.value = response.data.translation;
          output_vocab.value = response.data.keywords
        } else {
          alert("번역하려는 글의 길이가 너무 깁니다.")
        }
      } catch (error) {
        alert("번역하려는 글의 길이가 너무 깁니다.")
      } finally {
        loading.value = false;
      }
    }

    const searchKeyword = async () => {
      show_frequent_terms.value = false
      try {
        // Axios 요청을 보냅니다.
        const response = await axios.post('/staition_dev/search', {
          "user_input": keyword.value
        },);

        // 요청이 성공했고 결과가 true인 경우
        if (response.data.result === true) {
          output_keyword.value = response.data.keywords;
          page.value = 1;
          pagination();  // 페이지네이션 계산
          updateCurrentPageData();  // 현재 페이지에 맞는 데이터 업데이트
        } else {
          alert('검색에 실패했습니다.');
        }
      } catch (error) {
        console.error(error);
      } finally {
      }
    }

    onMounted(() => {
      const clipboard = new ClipboardJS('.tr_copy');

      clipboard.on('success', () => {
        showToast.value = true;
        setTimeout(() => {
          showToast.value = false;
        }, 2000); // 2초 후에 토스트 팝업 사라짐
      });

      clipboard.on('error', () => {
        alert('복사에 실패했습니다.');
      });
    });
    return {
      input_text,
      checkLength,
      loading,
      translate,
      output_text,
      showToast,
      output_vocab,
      keyword,
      searchKeyword,
      output_keyword,
      show_frequent_terms,
      frequent_terms,
      page,
      keyword_pagination,
      currentPageData,
      limit,
      handleClickPage
    };
  }
};

</script>


<template>
  <div>
    <section class="banner">
      <div class="container mx_wd">
        <h3 class="main_title">공시자료 영문 번역</h3>
        <p class="main_sub_title">글로벌 경쟁력 제고를 위한 공시자료 영문 번역 엔진을 경험해보세요.</p>
      </div>
    </section>
    <div class="bg">
      <section class="trans mx_wd">
        <div class="tr_section">
          <div class="detail_title">
            <h4></h4>
            <span class="version">v 1.0</span>
          </div>
          <div class="area">
            <label class="lang_title">한국어</label>
            <textarea id="input_text" name="input_text" v-model="input_text" @input="checkLength"></textarea>
            <template v-if="input_text != null">
              <button class="tr_reset"><img src="../assets/img/tr_reset.png" width="20" /></button>
            </template>
            <div class="tr_button_box">
              <div class="tr_length">
                <span :class="{ 'warn': input_text.length === 8000 }">{{ input_text.length }}</span>/<span>8000</span>
              </div>
              <div class="flex">
                <div></div>
                <button class="tr_submit lds-ellipsis" :class="{ 'active': input_text.length > 0 }" style="width: 132px"
                  :disabled="loading" @click="translate">
                  <template v-if="loading">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </template>
                  <template v-else>
                    번역하기
                  </template>
                </button>
              </div>
            </div>
          </div>
          <div class="area">
            <label class="lang_title">영어</label>
            <textarea id="output_text" name="output_text" readonly v-model="output_text"></textarea>
            <div class="tr_button_box">
              <template v-if="true">
                <div class="tr_length" style="color: #fff">krx</div>
                <div class="flex" v-if="output_text.length > 0">
                  <button class="tr_copy" data-clipboard-target="#output_text">복사하기</button>
                </div>
              </template>
            </div>
            <div v-if="showToast" class="toast-popup" :class="{ 'show': showToast }">복사되었습니다</div>
          </div>
          <span v-if="true" class="tr_direction">
            <img src="../assets/img/tr_direction.png" width="46" />
          </span>
        </div>
      </section>
      <template v-if="output_text.length > 0">
        <div class="divide_line mx_wd"></div>
        <div class="mx_wd">
          <h5 class="detail_title">추출된 {{ output_vocab.length }}개의 용어</h5>
          <ul class="result_cont">
            <li v-for="item, idx in output_vocab">
              <span class="num">{{ idx + 1 }}</span>
              <div class="result_cont_inner">
                <div class="source">{{ item.eng }}</div>
                <p class="target">{{ item.kor }}</p>
              </div>
            </li>
          </ul>
          <!-- <Pagination :currentPage="page" :totalPages="event_pagination" :handleClickPage="handleClickPage" /> -->
        </div>
      </template>
      <div class="divide_line mx_wd"></div>
      <section class="mx_wd">
        <div class="section_title">
          <h4>금융 용어집</h4>
          <div class="search_wrap">
            <label class="search_label"></label>
            <input type="text" id="keyword" name="keyword" v-model="keyword" placeholder="찾으시는 금융 용어가 있으세요?"
              @keyup.enter="searchKeyword" />
            <button title="search plane word" @click="searchKeyword"></button>
          </div>
        </div>
        <div v-if="show_frequent_terms">
          <div>
            <h5 class="detail_title">가장 많이 번역되는 금융 용어</h5>
            <ul class="example">
              <li v-for="item, index in frequent_terms" :key="index">
                <div class="target">{{ item.kor }}</div>
                <div class="source">{{ item.eng }}</div>
              </li>
            </ul>
          </div>
        </div>
        <div v-else>
          <p class="result_length">총 {{ output_keyword.length }}개의 용어</p>
          <ul class="result_cont">
            <template v-if="output_keyword.length > 0">
              <li v-for="item, idx in currentPageData" :key="idx">
                <span class="num">{{ (page - 1) * limit + idx + 1 }}</span>
                <div class="result_cont_inner">
                  <p class="target">{{ item.kor }}</p>
                  <div class="source">{{ item.eng }}</div>
                </div>
              </li>
            </template>
            <template v-else>
              <li style="text-align: center">검색 결과가 없습니다.</li>
            </template>
          </ul>
          <Pagination :currentPage="page" :totalPages="keyword_pagination" :handleClickPage="handleClickPage" />
        </div>
      </section>
    </div>


    <div id="toast" class="toast"></div>
  </div>
</template>
