import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import './assets/css/app.css'
import axios from 'axios'
// import { createI18n } from 'vue-i18n'
// import messages from './assets/lang/index'

const app = createApp(App)
// const browserLanguage = navigator.language.split('-')[0] // 사용자 브라우저 언어

// const i18n = createI18n({
//   locale: browserLanguage, // 기본 언어
//   allowComposition: true, // Vue3에서 사용하는 Composition API 모드 사용 여부
//   legacy: false, // Composition API 사용하는 경우 false
//   fallbackLocale: 'kr', // 기본값 대체 로케일
//   messages: messages, // 번역된 데이터들 상단 임포트한 파일
//   missingWarn: false, // 공란 데이터 워닝 안보이도록 처리
//   fallbackWarn: false, // 공란 데이터 워닝 안보이도록 처리
//   warnHtmlMessage: false // HTML 경고를 비활성화
// })
// app.provide('$i18n', i18n)
app.provide('$axios', axios)

axios.defaults.baseURL = 'https://3ya5oe7gfvbxsr-12211.proxy.runpod.net/'
app.use(router)
  // .use(i18n)
app.config.globalProperties.axios = axios;

app.mount('#app')