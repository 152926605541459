<script>
export default {
  data() {
    return {
      window: {
        width: 0,
        height: 0
      },
      // lang_popup: false,
    }
  },
  created() {
    window.addEventListener('resize', this.resizeHandler)
  },
  mounted() {
    this.getWindowSize()
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.resizeHandler)
  },
  methods: {
    resizeHandler() {
      this.getWindowSize()
    },
    getWindowSize() {
      this.window.width = window.innerWidth
      this.window.height = window.innerHeight
    },
    // langChange(lang) {
    //   if (lang == 'ko') {
    //     localStorage.setItem("language_set", "ko")
    //     this.$i18n.locale = 'ko'
    //   } else {
    //     localStorage.setItem("language_set", "en")
    //     this.$i18n.locale = 'en'
    //   }
    //   this.hamburger_active = false
    //   this.arrowRotate5 = false
    // },
  }
}

</script>
<template>
  <div>
    <header>
      <div class="mx_wd header">
        <div class="header_left">
          <a href="https://www.krx.co.kr/"><img src="./assets/img/krx_logo.png" width="276" alt='한국거래소 로고' /></a>
        </div>
        <div class="header_right">
          <div class="lexcode_direct" v-if="window.width > 768">
            <a href="mailto:pl@lexcode.com"><img src="./assets/img/mail_icon.png" width="24" alt='번역 문의 메일 보내기 버튼'>번역 문의
              사항 접수</a>
          </div>
          <!--
          <div class="lang_switch_inner">
            <img src="./assets/img/lang_icon.png" width="24" :alt='$t("message.alt_switch")' />
            <li>
              <a @mouseenter="lang_popup = true">
                <span style="text-transform: uppercase;">{{ $i18n.locale }}</span>
              </a>
            </li>
            <transition name="fade">
              <ul v-if="lang_popup" class="lang_list" @mouseleave="lang_popup = false">
                <li :class="{ active: $i18n.locale == 'ko' }" @click="langChange('ko')">KO</li>
                <li :class="{ active: $i18n.locale == 'en' }" @click="langChange('en')">EN</li>
              </ul>
            </transition>
            <img src="./assets/img/down_arrow.png" width="24" :alt='$t("message.alt_switch")' />
          </div>
          -->
        </div>
      </div>
    </header>
    <router-view class="content" :class="{ 'mobile': window.width < 769 }" :window="window"></router-view>
  </div>
</template>
